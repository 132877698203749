import { gql } from 'graphql.macro';

export const GET_BASE_CLIENT_DATA = gql`
  query {
    app @client {
      activeModule
      activeModuleContent
      showBeforeAndAfterMosaic
      baseParams {
        groupType
        territoryType
        territorySubtype
        territoryValueType
        crossingEnabled
        crossingTerritoryType
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossingTerritories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossing
        buffer
        yearRange
      }
    }
  }
`;

export const GET_BASE_DATA = gql`
  query {
    geometryBuffersList {
      id
      bufferSizeInMeters
			i18nStrings {
				language
				stringValue
			}
    }

   territoryCategoriesList {
     id
     key
     parentId
		 territoryLayerStyle
     i18nStrings {
			 language
			 stringValue
     }
   }
  }
`;

export const GET_TERRITORIES_LIST = gql`
  query TerritoriesList($categoryId: Int!) {
    territoriesList(categoryId: $categoryId) {
      id
      i18nStrings {
        language
        stringValue
      }
    }
  }
`;

export const GET_TERRITORY_BOUNDING_BOX = gql`
  query TerritoriesByIds($territoriesIds: [Int!]) {
    territoriesByIds(territoriesIds: $territoriesIds) {
      id
      territoryGeometry {
        boundingBox
      }
    }
  }
`;

export const GET_TRANSITION_PERIOD_OPTIONS_DATA = gql`
  query {
    coverageTransitionPeriodOptionsList {
      id
      fromYear
      toYear
      groupBy
      orderAmongSiblings
    }
  }
`;
