import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import {
  Query
} from 'react-apollo';

import {
  get,
  keys,
  map,
  concat,
  defaults,
} from 'lodash';

import {
  GET_USER,
  GET_USER_ID,
} from './queries';

import redirect from './redirect';
import AppContext from '../context/app-context';

import ConfirmationPage from '../../pages/ConfirmationPage';
import MapBiomasLandingPage from '../../pages/MapBiomasLandingPage';
import MapBiomasCreatePassword from '../../pages/MapBiomasCreatePassword';
import MapBiomasLogin from '../../pages/MapBiomasLogin';
import MapBiomasPasswordRecovery from '../../pages/MapBiomasPasswordRecovery';
import MapBiomasCreateAccount from '../../pages/MapbiomasCreateAccount';
import LoginToForum from '../../pages/LoginToForum';
import TerritoryDashboard from '../../pages/TerritoryDashboard';

import mapModuleToPathname from '../../data/mapModuleToPathname';

const modulesRoutes = map(keys(mapModuleToPathname), (pathname) => {
  return { path: pathname, component: MapBiomasLandingPage };
});

const routes = {
  all: [
    { path: '/', component: MapBiomasLandingPage },
    { path: '/agua', component: MapBiomasLandingPage },
    { path: '/monitor-do-fogo', component: MapBiomasLandingPage },
    { path: '/pistas-de-pouso', component: MapBiomasLandingPage },
    { path: '/dinamica-dos-pivos', component: MapBiomasLandingPage },

    // Modules pathname routes
    ...modulesRoutes,

    { path: '/recuperar-senha', component: MapBiomasCreatePassword },
    { path: '/login', component: MapBiomasLogin },
    { path: '/esqueci-minha-senha', component: MapBiomasPasswordRecovery },
    { path: '/criar-minha-conta', component: MapBiomasCreateAccount },
    { path: '/confirmar-conta', component: ConfirmationPage },
    { path: '/dashboard-territorio', component: TerritoryDashboard },
  ],
  unauthenticated: [
  ],
  authenticated: [
    { path: '/discourse/sso', component: LoginToForum },
  ],
};

const renderRoutes = ({ data, _loading }) => {
  let user = get(data, 'user', localStorage.getItem('token'));
  let routesToRender = user ? routes.authenticated : routes.unauthenticated;
  routesToRender = concat(routesToRender, routes.all);

  return (
    <Router>
      <AppContext.Provider value={ user }>
        <Switch>
          {
            map(routesToRender, (route, i) => {
              let routeProps = defaults({}, route, { key: i, exact: true });
              if (route.redirect) {
                routeProps.component = redirect(route.redirect);
              }

              return <Route { ...routeProps } />;
            })
          }
        </Switch>
      </AppContext.Provider>
    </Router>
  );
};

const Routes = () => (
  <Query query={ GET_USER_ID }>
    { ({ data, _loading }) => {
      return (
        <Query query={ GET_USER } variables={{ id: get(data, 'currentUserId', 0) }}>
          { renderRoutes }
        </Query>
      );
    } }
  </Query>
);

export default Routes;
