import React from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import numbro from 'numbro';

import { useIntl } from 'react-intl';

function tooltipFormatter(params) {
  const colorSpan = (color) => `<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${ color }"></span>`;
  let str = `<span>${ params[0].axisValue }</span>`;

  params.forEach((item) => {
    const value = _.isObject(item.data) ? item.data.value : item.data;

    if (value) {
      const valueLabel = numbro(value).format({
        thousandSeparated: true,
        mantissa: 0
      });
      const paramValue = `<span>${ colorSpan(item.color) } ${ item.seriesName }: ${ valueLabel }</span>`;

      str += paramValue;
    }
  });

  return str;
}

const mapMonthToIndex = {
  'Janeiro': 1,
  'January': 1,
  'Fevereiro': 2,
  'February': 2,
  'Março': 3,
  'March': 3,
  'Abril': 4,
  'April': 4,
  'Maio': 5,
  'May': 5,
  'Junho': 6,
  'June': 6,
  'Julho': 7,
  'July': 7,
  'Agosto': 8,
  'August': 8,
  'Setembro': 9,
  'September': 9,
  'Outubro': 10,
  'October': 10,
  'Novembro': 11,
  'November': 11,
  'Dezembro': 12,
  'December': 12,
};

const yAxisLabelFormatter = (value) => {
  return numbro(value / 1000000).format({
    thousandSeparated: true,
    mantissa: 0
  });
};

export default function BurnedAreaCustomChart({
  categories,
  data,
  vertical = true,
  customYAxisLabelFormatter = yAxisLabelFormatter,
}) {
  const intl = useIntl()
  const yAxisName = intl.formatMessage({id: 'mapbiomas.statistics.million_hectares'})

  let xAxis = {
    type: 'category',
    axisPointer: {
      type: 'shadow'
    },
    axisLabel: {
      fontSize: 11,
      color: '#767676',
      formatter: (value) => {
        return mapMonthToIndex[value] || value;
      }
    },
    axisLine: {
      color: '#DDD'
    },
    data: categories
  };
  let yAxis = {
    name: yAxisName || null,
    nameLocation: 'middle',
    nameRotate: 90,
    nameGap: 25,
    nameTextStyle: {
      fontSize: 11,
      color: '#767676'
    },
    axisTick: {
      show: false
    },
    axisLine: {
      color: '#DDD'
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(0, 0, 0, 0.05)'
      }
    },
    axisLabel: {
      formatter: (value) => {
        if (customYAxisLabelFormatter) {
          return customYAxisLabelFormatter(value);
        } else {
          const valueLabel = numbro(value).format({
            thousandSeparated: true,
            mantissa: 0
          });

          return valueLabel;
        }
      },
      fontSize: 11,
      color: '#767676'
    }
  };

  if (!vertical) {
    let tempValue = xAxis;
    xAxis = yAxis;
    yAxis = tempValue;
  }

  return (
    <ReactEcharts
      style={ { height: 200 } }
      option={ {
        xAxis,
        yAxis,
        tooltip: {
          confine: true,
          textStyle: {
            align: 'left',
            color: '#444',
            fontSize: 13
          },
          backgroundColor: '#FFFFFF',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          borderWidth: 1,
          extraCssText: 'box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);',
          trigger: 'axis',
          formatter: tooltipFormatter,
        },
        grid: {
          top: '8%',
          right: '10px',
          bottom: '12%',
          left: yAxisName ? '20px' : '10px',
          containLabel: true
        },
        legend: {
          data: [
            intl.formatMessage({id: 'mapbiomas.statistics.burned.max_value'}),
            intl.formatMessage({id: 'mapbiomas.statistics.burned.average_value'}),
            intl.formatMessage({id: 'mapbiomas.statistics.burned.min_value'}),
          ],
          bottom: 0,
          itemWidth: 15,
          itemHeight: 10,
          textStyle: {
            fontSize: 11,
            color: '#767676',
          }
        },
        series: [
          {
            name: 'Área queimada',
            type: 'bar',
            data: _.get(data, 'total'),
          },
          {
            symbol: 'none',
            name: intl.formatMessage({id: 'mapbiomas.statistics.burned.max_value'}),
            type: 'line',
            data: _.get(data, 'maximum'),
          },
          {
            symbol: 'none',
            name: intl.formatMessage({id: 'mapbiomas.statistics.burned.average_value'}),
            type: 'line',
            data: _.get(data, 'average'),
          },
          {
            symbol: 'none',
            name: intl.formatMessage({id: 'mapbiomas.statistics.burned.min_value'}),
            type: 'line',
            data: _.get(data, 'minimum'),
          },
        ]
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       name: 'MapBiomas',
        //       title: 'Download',
        //     }
        //   }
        // },
      } }
      notMerge={ true }
    />
  );
}
