import React from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import numbro from 'numbro';

function tooltipFormatter(mantissa = 0, params) {
  const colorSpan = (color) => `<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${ color }"></span>`;
  let str = `<span>${ params.seriesName }</span>`;

  const value = _.isObject(params.data) ? params.data.value : params.data;
  const valueLabel = numbro(value).format({
    thousandSeparated: true,
    mantissa,
  });
  const percentageFormatted = numbro(params.percent / 100).format({
    output: 'percent',
    mantissa: 2
  });
  const paramValue = `<span>${ colorSpan(params.color) } ${ _.get(params, 'data.name') }: ${ valueLabel } (${ percentageFormatted })</span>`;

  str += paramValue;

  return str;
}

export default function PieChart({
  colors,
  serie,
  unit,
  mantissa,
}) {
  return (
    <ReactEcharts
      style={ { height: 240, width: '100%' } }
      option={{
        grid: {
          left: 40,
          top: 0,
          right: 40,
          bottom: 20,
          // containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {
              name: 'MapBiomas',
              title: 'Download'
            }
          }
        },
        tooltip: {
            confine: true,
            trigger: 'item',
            textStyle: {
              align: "left",
              color: "#444",
              fontSize: 13
            },
            backgroundColor: "#FFFFFF",
            borderColor: "rgba(0, 0, 0, 0.1)",
            borderWidth: 1,
            extraCssText: "box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);",
            formatter: tooltipFormatter.bind(this, mantissa)
        },
        legend: {
          show: false
        },
        series: [
          {
            animation: false,
            name: serie.title,
            color: colors ? colors : ['#197D19', '#FFF27F', '#4BE64C', '#EEBFD6', '#D1C868', '#777'],
            type: 'pie',
            radius: '85%',
            data: serie.data,
            label: {
              show: false
            }
          }
        ],
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       name: 'MapBiomas',
        //       title: 'Download',
        //     }
        //   }
        // },
      }
    }
    />
  );
}
